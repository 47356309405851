import React from 'react';

const About = () => (
  <div>
    <h1>Ikoote</h1>
    <h1>Ikoote</h1>
  </div>
);

export default About;
